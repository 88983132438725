body .buorg {
  background-color: #2f3844;
  border-bottom: 0px solid #2f3844;
  color: #e3e6eb;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0);
}

body #buorgul {
  background: #086be5;
}
